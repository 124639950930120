import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { ILinkedPages, ITableColumn } from '@Models/table-column';
import { ITableData } from '@Models/table-data';
import { SelectionModel } from '@angular/cdk/collections';
import { TableService } from '@Services/table.service';
import { ITableList } from '@Models/table-list';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { EditListComponent } from '../table-list-view/edit-list/edit-list.component';
import { ChooseListComponent } from '../choose-list/choose-list.component';
import {
  ColumnSetupComponent,
  IColumnSetupOutput,
} from '../column-setup/column-setup.component';
import { ListService } from '@Services/list.service';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { IEditMemo } from '../shared/Models/edit-memo';
import { ILangString } from '@Models/lang-string';
import { IFormData } from '@Models/form-data';
import { AppService } from '@Services/app.service';

@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss'],
})
export class TableViewComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  private dataArray!: any[];
  private originalData!: any[];
  private defaultNewRecord: any;
  private originalColumnQueryString!: string;
  private tableData!: ITableData;

  public table!: string;
  public primaryKey!: string;

  public allColumns!: ITableColumn[];
  public displayedColumns!: ITableColumn[];
  private languageStringDictionary?: { [code: string]: string };

  public tableInfoArray: any;
  public dataSource!: MatTableDataSource<any>;
  public selection = new SelectionModel<any>(true, []);

  public sortBy!: string;
  public sortDir!: SortDirection;
  public filterValue = '';
  public currentList!: number | null;
  public stickyColumns = 0;
  private currentColumns!: string | null;

  public allowUpdate = false;
  public allowAdd = false;
  public allowDelete = false;
  
  public useForm = false;
  public formAllowAdd = false;
  public searchLangString: { column: ITableColumn, row: any } | undefined;

  public isLoading = 0;
  public userIsEditing = false;
  public addingNewRows = false;
  public showSelectColumn = false;
  public hasArtNr = false;
  public editMemo: IEditMemo | undefined = undefined;
  public formData: IFormData | undefined = undefined;

  public currentListName = '';
  private currentListColumns = '';

  @ViewChild(MatSort) sort = new MatSort();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private tableService: TableService,
    private listService: ListService,
    private cookieService: CookieService,
    private dialog: MatDialog,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(environment.AppName);
    
    this.loadTableList();

    this.route.paramMap.subscribe({
      next: (params: ParamMap) => {
        let tableId = params.get('id');
        if (tableId) {
          this.table = tableId;
          this.sortBy = this.route.snapshot.queryParamMap.get('order')!;
          this.sortDir =
            this.route.snapshot.queryParamMap.get('dir') == 'desc'
              ? 'desc'
              : 'asc';
          this.filterValue = this.route.snapshot.queryParamMap.get('filter')!;
          this.currentColumns =
            this.route.snapshot.queryParamMap.get('columns')!;

          const sticky = this.route.snapshot.queryParamMap.get('sticky')!;
          this.stickyColumns = sticky ? Number.parseInt(sticky) : 0;

          const lst = this.route.snapshot.queryParamMap.get('list');
          this.currentList = lst ? Number.parseInt(lst) : 0;

          this.loadTable();
        } else if (this.tableService.latestTable) {
          this.table = this.tableService.latestTable;
          this.loadTable();
        }
      },
    });
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  canDeactivate(): Observable<boolean> {
    if (this.userIsEditing) {
      const result = window.confirm('There are unsaved changed! Are you sure?');
      return of(result);
    }

    return of(true);
  }

  loadTableList() {
    this.isLoading += 1;

    this.tableService.latestTable = this.table;
    this.subscriptions.add(
      this.appService.getMe().subscribe({
        next: (res) => this.tableInfoArray = res?.listedEntities ?? []
    }));
  }

  loadTable() {
    this.isLoading += 1;
    this.selection.clear();

    this.subscriptions.add(
      this.tableService.get(this.table).subscribe({
        next: (res: ITableData) => {
          this.tableData = res;
          this.hasArtNr = false;

          if (res.rows) {
            this.cleanData(res);
            this.dataArray = res.rows;
            this.originalData = JSON.parse(JSON.stringify(res.rows));
          } else {
            this.dataArray = [];
          }

          this.allColumns = res.columns.sort((a, b) => a.position - b.position);
          this.displayedColumns = [];
          this.createDataSource();
          this.primaryKey = res.primaryKey;
          this.languageStringDictionary = res.languageStrings;
          this.useForm = res.useForm;
          this.allowUpdate = res.allowUpdate;
          this.allowAdd = res.allowAdd;
          this.allowDelete = res.allowDelete;
          this.defaultNewRecord = res.defaultNewRecord;
          this.originalColumnQueryString = this.createColumnString(res.columns);
          this.userIsEditing = false;
          this.addingNewRows = false;

          if (this.table != 'articles' && res.columns.find(c => c.id == 'articleNumber'))
            this.hasArtNr = true;

          if (this.currentList) {
            this.openList(this.currentList);
          } else if (this.currentColumns) {
            this.displayedColumns = this.getColumnsList(this.currentColumns);
          } else {
            const cookieColumnStr = this.getColumnStringFromCookie();
            if (cookieColumnStr) {
              this.currentColumns = cookieColumnStr;
              this.displayedColumns = this.getColumnsList(cookieColumnStr);
              this.queryParamsChanged();
            } else {
              this.setDisplayColumnsToAll();
            }
          }

          this.titleService.setTitle(environment.AppName + ' - ' + this.table);

          this.isLoading -= 1;
        },
        error: (err: HttpErrorResponse) => {
          this.isLoading -= 1;
          this.handleError(err);
        }
      })
    );
  }

  createDataSource(dataArray: any = null) {
    this.dataSource = new MatTableDataSource(dataArray ?? this.dataArray);
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.filterValue;

    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string | number => {
      const column = this.displayedColumns.find(c => c.id === sortHeaderId);
      if (column?.type === 'choice') {
        return column.possibleValues.find(v => v.value === data[sortHeaderId])?.label ?? '';
      } else {
        return data[sortHeaderId];
      }
    }
  }

  setDisplayColumnsToAll() {
    let col: ITableColumn[] = [];
    this.allColumns.forEach((c: any) => col.push(Object.assign({}, c)));
    this.displayedColumns = col;
  }

  cleanData(data: ITableData) {
    data.columns.forEach((c) => {
      if (c.type.startsWith('date')) {
        data.rows.forEach((r: any) => {
          if (r[c.id]) r[c.id] = r[c.id].split('.')[0];
        });
      } else if (c.type === 'choice') {
        data.rows.forEach((row: any) => {
          const label = this.getChoiceLabel(row[c.id], c).toLocaleLowerCase();

          if (!row['#choices']) 
            row['#choices'] = label;
          else
            row['#choices'] += label;
        });
      }
    });
  }

  tableChanged(newTable: string) {
    this.router.navigate([`/tables/${newTable}`]);
  }

  sortChanged(event: Sort) {
    this.sortBy = event.active;
    this.sortDir = event.direction;
    this.queryParamsChanged();
  }

  queryParamsChanged() {
    let params: any = {};
    if (this.sortBy) {
      params.order = this.sortBy;
      if (this.sortDir) params.dir = this.sortDir;
    }
    if (this.filterValue) params.filter = this.filterValue;
    if (this.currentList) params.list = this.currentList;
    if (this.currentColumns) params.columns = this.currentColumns;
    if (this.stickyColumns && this.stickyColumns >= 0)
      params.sticky = this.stickyColumns;

    this.router.navigate([`/tables/${this.table}`], { queryParams: params });
  }

  columnArray(): string[] {
    if (this.displayedColumns) {
      let cols = this.displayedColumns
        .filter((c) => c.visible)
        .map((c) => c.id);
      if (this.useForm) cols = ['edit', ...cols];
      if (this.showSelectColumn) cols = ['select', ...cols];
      
      return cols;
    } else {
      return [];
    }
  }

  rowCellClass(column: ITableColumn, element: any): string {
    if (column.allowEdit) {
      if (element.changed && element.changed.includes(column.id)) {
        return 'changed-mat-cell';
      }
      return 'edit-mat-cell';
    }
    return 'no-edit-mat-cell';
  }

  getChoiceLabel(value: any, column: ITableColumn): string {
    return !value 
      ? '' 
      : column.possibleValues.find((v) => v.value === value)?.label ?? '';
  }

  getLangStringText(str?: string): string {
    if (str && this.languageStringDictionary) {
      return this.languageStringDictionary[str];
    }

    return '';
  }

  onMemoChange(editMemo: IEditMemo) {
    this.dataChanged(editMemo.data, editMemo.column.id);
  }

  setEditMemo(data: any, column: ITableColumn) {
    if (column.type === 'textarea' && !this.useForm) {
      this.editMemo = { data: data, column: column };

      setTimeout(() => {
        const area = document.getElementById('memo-area');
        area?.focus();
      }, 100);
    }
  }

  openForm(row: any) {
    if (this.useForm && row) {
      this.tableService.get(this.table, row[this.primaryKey]).subscribe({
        next: (res) => {
          this.formData = {
            name: res.name,
            rowId: row[this.primaryKey],
            table: this.table
          };
        },
        error: (err) => console.log(err)
      });      
    }
  }

  formClosing(row: any) {
    if (this.formData && row) {
      this.loadTable();
    }
    this.formData = undefined;
  }

  private timer: any;
  filterChanged() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.applyFilter();
    }, 1000);
  }

  clearFilter() {
    this.filterValue = '';
    this.applyFilter();
  }

  applyFilter() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    this.queryParamsChanged();
  }

  dataChanged(element: any, column: string) {
    if (!element.new) {
      const origRow = this.originalData.find(row => row[this.primaryKey] === element[this.primaryKey]);
      if (origRow[column] === element[column])
        return;
    }

    if (!element.changed)
      element.changed = []

    element.changed.push(column);
    this.userIsEditing = true;
  }

  saveData() {
    let sendChangesToServer = true;
    let allUpdatesDone = true;
    let allAddsDone = true;
    let listOfSaves: Observable<any>[] = [];

    this.dataArray.forEach((record) => {
      if (record.new) {
        allUpdatesDone = false;

        if (this.checkRecord(record)) {
          record.newRecord = true;
          listOfSaves.push(this.tableService.post(this.table, record));
        } else sendChangesToServer = false;
      } else if (record.changed) {
        allUpdatesDone = false;

        if (this.checkRecord(record)) {
          listOfSaves.push(this.tableService.put(this.table, record));
        } else sendChangesToServer = false;
      }
    });

    if (allAddsDone) {
      this.addingNewRows = false;
      if (allUpdatesDone) {
        this.userIsEditing = false;
      }
    }

    if (listOfSaves.length > 0 && sendChangesToServer) {
      this.isLoading += 1;
      forkJoin(listOfSaves).subscribe({
        complete: () => {
          this.isLoading -= 1;
          this.addingNewRows = false;
          this.userIsEditing = false;
          this.loadTable();
        },
      });
    }
  }

  checkRecord(r: any): boolean {
    let res = true;
    r.missing = null;
    this.allColumns.forEach((col: ITableColumn) => {
      if (
        col.required &&
        !r[col.id] &&
        !(r[col.id] === 0 || r[col.id] === false)
      ) {
        res = false;

        if (!r.missing) r.missing = [col.id];
        else r.missing.push(col.id);
        return;
      }
    });
    return res;
  }

  isMissingValue(element: any, col: string): boolean {
    if (element.missing && element.missing.find((e: any) => e === col)) {
      return true;
    }
    return false;
  }

  refreshData() {
    this.filterValue = '';
    this.sortBy = '';
    this.sortDir = '';
    this.currentList = null;
    this.currentListName = '';
    this.queryParamsChanged();
    this.loadTable();
  }

  addRecord() {
    if (this.useForm) {
      this.formData = {
        name: this.tableData.name,
        rowId: 0,
        table: this.table
      };
    } else {
      let newRow = JSON.parse(JSON.stringify(this.defaultNewRecord));
      newRow.new = true;

      this.addingNewRows = true;
      this.userIsEditing = true;

      this.dataArray = [newRow, ...this.dataArray]
      this.createDataSource();
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.filteredData);
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  toggleSelect() {
    if (this.showSelectColumn) {
      this.selection.clear();
      this.showSelectColumn = false;
    } else {
      this.showSelectColumn = true;
    }
  }

  deleteSelected() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete records',
        message: 'Are you sure you want to delete these records?',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) this.doDeleteSelected();
    });
  }

  doDeleteSelected() {
    this.isLoading += 1;
    let ids: number[] = [];
    this.selection.selected.forEach((record) => {
      ids.push(record[this.primaryKey]);
    });
    this.tableService.delete(this.table, ids).subscribe({
      next: () => {
        this.refreshData();
        this.isLoading -= 1;
      },
      error: (err) => {
        this.handleError(err);
        this.isLoading -= 1;
      },
    });

    this.showSelectColumn = false;
    this.selection.clear();
  }

  //// WebLists

  showChooseList(addRecords: boolean = false) {
    this.listService.getLists(this.table).subscribe({
      next: (res) => {
        const dialogRef = this.dialog.open(ChooseListComponent, {
          data: res.sort((a, b) => a.name < b.name ? 1 : -1)
        });

        dialogRef.afterClosed().subscribe((listId) => {
          if (listId) {
            if (addRecords) {
              this.addToExistingList(listId);
            }
            else {
              this.openList(listId);
            }
          }
        });
      },
      error: (err) => this.handleError(err),
    });
  }

  openList(id: number) {
    if (this.currentColumns) this.saveColumnsToCookie();
    this.isLoading += 1;
    this.listService.getList(this.table, id).subscribe({
      next: (res) => {
        let listData = this.dataArray.filter((r) => {
          return res.records.includes(r[this.primaryKey]);
        });
        this.createDataSource(listData);
        this.currentListName = res.name;
        this.currentList = id;
        this.currentListColumns = res.columns;

        if (res.columns) {
          this.displayedColumns = this.getColumnsList(res.columns);
          this.currentColumns = '';
        }

        this.queryParamsChanged();
      },
      error: (err) => {
        this.handleError(err);
      },
      complete: () => this.isLoading -= 1
    });
  }

  createTableList() {
    let colString = '';
    this.displayedColumns.forEach((c) => {
      if (c.visible) colString += c.id + ',';
    });

    let newList: ITableList = {
      id: 0,
      table: this.table,
      name: '',
      private: true,
      columns: colString,
      records: [],
    };
    this.selection.selected.forEach((r) =>
      newList.records.push(r[this.primaryKey])
    );

    const dialogRef = this.dialog.open(EditListComponent, {
      data: {
        title: 'Create List',
        list: newList,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (list: ITableList) => {
        if (list) {
          this.isLoading += 1;
          this.listService.createList(list).subscribe({
            next: (id: any) => {
              this.showSelectColumn = false;
              this.selection.clear();
              this.openList(id);
            },
            error: (err) => {
              this.handleError(err);
            },
            complete: () => this.isLoading -= 1
          });
        }
      },
    });
  }

  addToExistingList(id: number) {
    let records: number[] = [];
    this.selection.selected.forEach((r) => records.push(r[this.primaryKey]));

    this.isLoading += 1;
    this.listService.appendRecordsToList(id, records).subscribe({
      next: () => {
        this.showSelectColumn = false;
        this.selection.clear();
      },
      error: (err) => {
        this.handleError(err);
      },
      complete: () => this.isLoading -= 1
    });
  }

  removeSelectedFromList() {
    if (!this.currentList) return;

    let records: number[] = [];
    this.selection.selected.forEach((r) => records.push(r[this.primaryKey]));

    this.isLoading += 1;
    this.listService
      .removeRecordsFromList(this.currentList!, records)
      .subscribe({
        next: () => {
          this.showSelectColumn = false;
          this.selection.clear();
          this.isLoading -= 1;
          this.openList(this.currentList!);
        },
        error: (err) => {
          this.handleError(err);
        },
        complete: () => this.isLoading -= 1
      });
  }

  filterByArticles() {
    this.listService.getLists('articles').subscribe({
      next: (res) => {
        const dialogRef = this.dialog.open(ChooseListComponent, { data: res });
        dialogRef.afterClosed().subscribe((listId) => {
          if (listId)
            this.filterOnArticlesList(listId);
        });
      },
      error: (err) => this.handleError(err),
    });
  }

  filterOnArticlesList(listId: number) {
    this.isLoading += 1;
    this.listService.getArticleList(listId).subscribe({
      next: (res: any) => {
        let listData = this.dataArray.filter((r) => {
          return res.includes(r.articleNumber);
        });
        this.createDataSource(listData);
      },
      error: (err) => {
        this.handleError(err);
      },
      complete: () => this.isLoading -= 1
    });
  }

  linkedPageClicked(link: ILinkedPages, data: any) {    
    let params: any = {};
    if (link.dashboard) {
      if (link.addToParam)
        params[link.addToParam] = data.toString();    

      this.router.navigate([`/dashboard/${link.dashboard}`], { queryParams: params });
    } else if (link.externalUrl) {
      let finalLink = link.externalUrl;
      if (link.addToParam)
        finalLink.replace(`@${link.addToParam}`, data.toString());

      window.open(finalLink, "target=_blank");
    }      
  }

  // Columns

  showColumnDialog() {
    let columnChoiceData: ITableColumn[] = [];
    if (this.currentColumns) {
      columnChoiceData = this.getColumnChoiceData(this.currentColumns);
    } else if (this.currentList) {
      columnChoiceData = this.getColumnChoiceData(this.currentListColumns);
    } else {
      this.allColumns.forEach((c) =>
        columnChoiceData.push(Object.assign({}, c))
      );
    }

    const dialogRef = this.dialog.open(ColumnSetupComponent, {
      data: {
        columnData: columnChoiceData,
        stickyColumns: this.stickyColumns,
        isList: this.currentList ? true : false,
      },
    });

    dialogRef.afterClosed().subscribe((res: IColumnSetupOutput) => {
      if (res) {
          if (res.doReset) this.resetColumns();
          else if (this.currentList && res.saveColumnsToList)
            this.saveListColumnSetup(res);
          else this.saveColumnSetup(res);
      }
    });
  }

  getColumnChoiceData(str: string): ITableColumn[] {
    let columnChoiceData: ITableColumn[] = [];
    str.split(',').forEach((c, i) => {
      const orig = this.allColumns.find((k) => k.id === c);
      if (orig) {
        let col = Object.assign({}, orig);
        col.visible = true;
        col.position = i;
        columnChoiceData.push(col);
      }
    });
    this.allColumns.forEach((c) => {
      if (!str.includes(c.id)) {
        let col = Object.assign({}, c);
        col.visible = false;
        col.position = columnChoiceData.length;
        columnChoiceData.push(col);
      }
    });

    return columnChoiceData;
  }

  resetColumns() {
    this.currentColumns = null;
    this.stickyColumns = 0;

    if (this.currentList) {
      this.displayedColumns = this.getColumnsList(this.currentListColumns);
    } else {
      this.setDisplayColumnsToAll();
      this.saveColumnsToCookie();
    }

    this.queryParamsChanged();
  }

  saveListColumnSetup(data: IColumnSetupOutput) {
    this.currentColumns = null;
    this.stickyColumns = data.stickyColumns;
    this.displayedColumns = [];
    data.columnData.forEach((c) =>
      this.displayedColumns.push(Object.assign({}, c))
    );
    this.currentListColumns = this.createColumnString(data.columnData);

    this.isLoading += 1;
    this.listService
      .changeColumnsForList(this.currentList!, this.currentListColumns)
      .subscribe({
        next: () => {
          this.queryParamsChanged();
        },
        error: (err) => {
          this.handleError(err);
        },
        complete: () => this.isLoading -= 1
      });
  }

  saveColumnSetup(data: IColumnSetupOutput) {
    this.currentColumns = this.createColumnString(data.columnData);
    this.stickyColumns = data.stickyColumns ? data.stickyColumns : 0;

    this.displayedColumns = [];
    data.columnData.forEach((c) =>
      this.displayedColumns.push(Object.assign({}, c))
    );

    if (!this.currentList) {
      if (this.originalColumnQueryString == this.currentColumns)
        this.currentColumns = null;
      this.saveColumnsToCookie();
    } else if (this.currentListColumns == this.currentColumns) {
      this.currentColumns = null;
    }

    this.queryParamsChanged();

    // To get the table to update when stickyColumns = 0 BUG in MatTable?
    this.dataSource.filter = 'qw+t34j';
    this.dataSource.filter = this.filterValue;
  }

  private getColumnsList(columnStr: string): ITableColumn[] {
    const colStrList = columnStr.split(',');
    let columnList: ITableColumn[] = [];
    colStrList.forEach((s) => {
      let col = this.allColumns.find((c: ITableColumn) => c.id == s);
      if (col) columnList.push(col);
    });
    return columnList;
  }

  private createColumnString(columnArray: ITableColumn[]): string {
    return columnArray
      .sort((a, b) => a.position - b.position)
      .filter((c) => c.visible)
      .map((c) => c.id)
      .toString();
  }

  openLangStringForm(column: ITableColumn, row: any) {
    this.searchLangString = {
      column: column,
      row: row
    };
  }

  onLangStringFormClosing(str?: ILangString) {
    if (this.searchLangString) {
      if (str) {
        this.searchLangString.row[this.searchLangString.column.id] = str.code;
      }
      this.searchLangString = undefined;
    }
  }

  /// Cookies

  private saveColumnsToCookie() {
    this.cookieService.set(
      this.cookieName,
      this.currentColumns ? this.currentColumns : ''
    );
  }

  private getColumnStringFromCookie(): string {
    if (this.cookieService.check(this.cookieName))
      return this.cookieService.get(this.cookieName);
    return '';
  }

  private get cookieName(): string {
    return this.table + '-columns';
  }

  /// ERRROr hANdLlng

  handleError(err: Error) {
    console.log(err);
  }
}
