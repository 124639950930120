import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationInsightService } from '@Services/app-insight/application-insight.service';
import { AuthService } from './auth/auth.service';
import { IMenuSetup } from '@Models/menu-setup';
import { AppService } from '@Services/app.service';
import { IMe } from '@Models/me';
import { first } from 'rxjs';
import { environment } from '@Env/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  me!: IMe;
  customMenuItems!: IMenuSetup[];

  public title = 'SSoT';
  public showErrorReportForm = false;

  constructor(
    private appInsightService: ApplicationInsightService,
    private authService: AuthService,
    private router: Router,
    private appService: AppService) {}

  ngOnInit() {
    if (this.isAuthenticated) {
      this.setupUser();
    } else {
      this.authService.observable().pipe(first()).subscribe({
        next: () => this.setupUser()
      })
    }
  }

  setupUser() {
    this.appService.getMe().subscribe({
      next: (res: IMe) => {
        this.me = res
        this.customMenuItems = res.menuItems.sort((a, b) => a.position - b.position);
      }
    });
  }

  logout() {
    this.authService.signOut();
    this.router.navigate(['signed-out']);
  }

  get isAuthenticated(): boolean {
    return this.authService.isAuthenticated;
  }

  sortedSubMenuItemList(node: IMenuSetup): IMenuSetup[] {
    if (node.subMenuItemList) {
      return node.subMenuItemList.sort((a, b) => a.position - b.position);
    }
    return [];
  }

  public getSettingsMenu() {
    let menu = this.settingsMenu;

    if (this.me?.hasListedEntities) {
      menu = [ ...menu, this.settingsMenuTable ];
    }
    if (this.me?.supportLevel) {
      menu = [ ...menu, this.settingsMenuSupport ];
    }

    return menu;
  }

  public openErrorReport() {
    this.showErrorReportForm = true;

    setTimeout(() => {
      const area = document.getElementById('summary-input');
      area?.focus();
    }, 100);
  }

  public settingsMenu: any[] = [    
    {
      label: 'Language Strings',
      link: '/languagestrings'
    },
    // {
    //   label: 'Manage Lists',
    //   link: '/lists'
    // },
    {
      label: 'Resource Links',
      link: '/resourcelist'
    },
    // {
    //   label: 'Run Custom Query',
    //   link: '/custom-queries'
    // }    
  ]

  settingsMenuTable = {
    label: 'Tables',
    link: '/tables'
  };
  
  settingsMenuSupport = {
    label: 'Support',
    link: '/dashboard/support'
  };

  get drawerClass(): string {
    if (environment.envName === 'Dev')
      return 'drawer-dev';
    if (environment.envName === 'Test')
      return 'drawer-test';
    return 'drawer';
  }
}
