import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, shareReplay } from "rxjs";
import { IMe } from "@Models/me";
import { IMenuSetup } from "@Models/menu-setup";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private cache!: Observable<IMe>;

  constructor(private http: HttpClient) {}

  getMe() : Observable<IMe> {
    if (this.cache)
      return this.cache;
    let url = environment.apiUrl + 'me';
    this.cache = this.http.get<IMe>(url).pipe(
      shareReplay(1)
    );

    return this.cache;
  }

  sendErrorReport(data: any) : Observable<any> {
    let url = environment.apiUrl + 'errorreport';
    return this.http.post(url, data);
  }

  updateErrorReport(id: number, data: any) : Observable<any> {
    let url = environment.apiUrl + 'errorreport';
    return this.http.put(url, data);
  }

  getErrorReport(id: number) : Observable<any> {
    let url = environment.apiUrl + 'errorreport/' + id;
    return this.http.get(url);
  }

  getActiveErrorReports() : Observable<any> {
    let url = environment.apiUrl + 'errorreport';
    return this.http.get(url);
  }
}
