<mat-drawer-container class="sidenav-container">
  <mat-drawer [class]="drawerClass" mode="side" opened>
    <div #top class="top-button-bar">
    <button mat-icon-button color="primary" matTooltip="Main dashboard" [matTooltipPosition]="'right'" aria-label="Dashboard" class="nav-button" routerLink="/dashboard/main" routerLinkActive="active">
      <mat-icon>home</mat-icon>
    </button>
  </div>
  <div *ngIf="customMenuItems" #custom class="custom-button-bar">
    <ng-container *ngFor="let node of customMenuItems">
      <button *ngIf="node.dashboardName" mat-icon-button [matTooltip]="node.label" [matTooltipPosition]="'right'" color="primary" class="nav-button" aria-label="custom" [routerLink]="'/dashboard/' + node.dashboardName">
        <mat-icon>{{ node.icon }}</mat-icon>
      </button>
      <app-menu *ngIf="!node.dashboardName" [data]="sortedSubMenuItemList(node)" [icon]="node.icon" [label]="node.label" [isRootNode]="true" class="app-menu-button"></app-menu>
  </ng-container>
  </div>
  <div #bottom class="bottom-button-bar">
    <button mat-icon-button matTooltip="Utilities" matTooltipPosition="right" color="primary" class="nav-button" [matMenuTriggerFor]="menu">
      <mat-icon>settings</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let node of getSettingsMenu()">
        <button mat-menu-item [routerLink]="node.link">{{ node.label }}</button>
      </ng-container>
    </mat-menu>
    <button *ngIf="me?.isAdministrator" mat-icon-button color="primary" [matTooltipPosition]="'right'" matTooltip="Administration" aria-label="Admin" class="nav-button" routerLink="/admin" routerLinkActive="active">
      <mat-icon>security</mat-icon>
    </button>
    <button *ngIf="isAuthenticated" mat-icon-button color="primary" [matTooltipPosition]="'right'" matTooltip="Report Error" aria-label="Report Error" class="nav-button bottom" (click)="openErrorReport()">
      <mat-icon>report</mat-icon>
    </button>
    <button *ngIf="isAuthenticated" mat-icon-button color="primary" [matTooltipPosition]="'right'" matTooltip="Log out" aria-label="Log out" class="nav-button bottom" (click)="logout()">
      <mat-icon>logout</mat-icon>
    </button>
    <button *ngIf="!isAuthenticated" mat-icon-button color="primary" [matTooltipPosition]="'right'" matTooltip="Login" aria-label="Login" class="nav-button bottom" routerLink="/login" routerLinkActive="active">
      <mat-icon>login</mat-icon>
    </button>
  </div>
  </mat-drawer>
  <mat-drawer-content class="content">
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>

<app-error-report
  *ngIf="showErrorReportForm"
  (onFormClosing)="showErrorReportForm = false">
</app-error-report>