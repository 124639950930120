import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ITableData } from '@Models/table-data';
import { forkJoin, map, Observable, tap } from 'rxjs';
import { IListedEntity } from '@Models/listed-object';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  public latestTable = '';
  constructor(private http: HttpClient) {} 
    // private cache: CacheService<ITableData>

  get(endpoint: string, id?: number) : Observable<ITableData> {
    let realEndpoint = endpoint.replace('-', '/');
    let url = environment.apiUrl + realEndpoint;

    if (id) {
      url +=  (url.endsWith('/') ? id : `/${id}`);
    }
    
    return this.http.get<ITableData>(url);
  }

  // getCached(endpoint: string) : Observable<ITableData> {
  //   let realEndpoint = endpoint.replace('-', '/');
  //   let url = environment.apiUrl + realEndpoint;
    
  //   let data$ = this.cache.getValue(endpoint);

  //   if (!data$) {
  //     data$ = this.http.get<ITableData>(url).pipe(
  //       map((response: any) => response), shareReplay(1)
  //     );

  //     this.cache.setValue(data$);
  //   }
  //   return data$;
  // }

  post(endpoint: string, object: any) : Observable<any> {
    const url = environment.apiUrl + endpoint;
    
      return this.http.post(url, object);
  }

  put(endpoint: string, object: any) : Observable<any> {
    const url = environment.apiUrl + endpoint;
    return this.http.put(url, object)
      .pipe(
        map((res: any) => {
          if (res) {
            if (res.errorMessage) {
              window.alert(res.errorMessage);
            }

            return res.entity;
          }

          return null; 
        })
      );
  }

  delete(endpoint: string, ids: any[]) : Observable<any[]> {
    const url = environment.apiUrl + endpoint;
    let obs: Observable<any>[] = [];
    ids.forEach((id) => {
      let urli = url + '/' + id;
      obs.push(this.http.delete(urli));
    });

    return forkJoin(obs);
  }

  getNewTemplate(endpoint: string) : Observable<ITableData> {
    const url = environment.apiUrl + endpoint + (endpoint.endsWith('/') ? 'default' : '/default');
    return this.http.get<ITableData>(url);
  }

  sendAction(endpoint: string) : Observable<any> {
    const url = environment.apiUrl + endpoint;    
    return this.http.put(url, null)
      .pipe(
        tap((res: any) => {
          if (res && res.error) {
            window.alert(res.message);
          }
        })
      );
  }
}
